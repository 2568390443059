import React from "react";

export default function HeroSection(props) {
  return (
    <div
      className={`bg-image ${props.className} footer`}
      style={{
        backgroundImage: `url("${props.image}")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: props.height,
      }}
    >
      {props.children}
    </div>
  );
}
