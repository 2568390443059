import React from "react";
import {
  FaPinterestSquare,
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaTwitterSquare,
  FaYoutubeSquare,
  FaRedditSquare,
} from "react-icons/fa";
import HeroSection from "../HeroSection";
import "./styles.css";
import Background from "../../assets/images/Rectangle_15.svg";
import Logo from "../../assets/images/betrics_logo_w.svg";
import GooglePlay from "../../assets/images/play_store.svg";
import AppStore from "../../assets/images/app_store.svg";
import SuspenseImage from "../SuspenseImage";

export default function Footer() {
  return (
    <>
      <HeroSection image={Background} height={250}>
        <div className="layer">
          <div className="d-flex h-100 justify-content-center">
            <div className="text-center text-white">
              <p className="mb-3 startText">
                Start making better wagering decisions faster <br />
                TODAY with your Betrics framework.
              </p>
              <div className="subscribeForm">
                <input type="email" placeholder="Email id" />
                <button className="cust-btn">GET OUR APP</button>
              </div>
            </div>
          </div>
        </div>
      </HeroSection>
      <div className="footer">
        <footer className="text-center text-lg-start text-white pt-5 footerContainer">
          <section className>
            <div className="container text-center text-md-start mt-5">
              <div className="row mt-3">
                <div className="col-md-3 mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold">
                    <SuspenseImage src={Logo} alt="Betrics" />
                  </h6>
                  <p className="mt-3 descText">
                    At Betrics Co. we know you want to be a profitable sports
                    bettor. In order to do that, you need to learn how to
                    identify bets that have value. The problem is that the
                    sports betting markets are efficient and data-driven with a
                    lot of choices to consider.
                  </p>
                </div>
                <div className="col-md-2 mx-auto mb-4">
                  <h6 className="fw-bold">Useful Links</h6>
                  <hr className="mb-4 mt-0 d-inline-block mx-auto lineStyle" />
                  <div className="row">
                    <div className="col-6">
                      <p>
                        <a href="/" className="text-white">
                          Home
                        </a>
                      </p>
                      <p>
                        <a href="/about-us" className="text-white">
                          About Us
                        </a>
                      </p>
                      <p>
                        <a href="/our-app" className="text-white">
                          Our App
                        </a>
                      </p>
                      <p>
                        <a href="contact-us" className="text-white">
                          Contact Us
                        </a>
                      </p>
                    </div>

                    <div className="col-6">
                      <p>
                        <a href="https://www.fundable.com/betrics" target="_blank" className="text-white">
                          Investors
                        </a>
                      </p>
                      <p>
                        <a href="https://betrics.gitbook.io/betrics/" target="_blank" className="text-white">
                          Betrics University
                        </a>
                      </p>
                      <p>
                        <a href="https://discord.gg/gNPuqCnp" target="_blank" className="text-white">
                          Join Our Community
                        </a>
                      </p>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-5 mx-auto mb-4">
                  <h6 className="fw-bold">Integration Partners</h6>
                  <hr className="mb-4 mt-0 d-inline-block mx-auto lineStyle" />
                  <div className="row">
                    <div className="col-6">
                      <p>
                        <a href="#!" className="text-white">
                          SportRadar
                        </a>
                      </p>
                      <p>
                        <a href="#!" className="text-white">
                          RotoWire
                        </a>
                      </p>
                      <p>
                        <a href="#!" className="text-white">
                          SmileCoin
                        </a>
                      </p>
                      <p>
                        <a href="#!" className="text-white">
                          Help
                        </a>
                      </p>
                    </div>
                    <div className="col-6">
                      <p>
                        <a href="#!" className="text-white">
                          Quarter4
                        </a>
                      </p>
                      <p>
                        <a href="#!" className="text-white">
                          CustomDFS
                        </a>
                      </p>
                      <p>
                        <a href="#!" className="text-white">
                          Grin Gaming?
                        </a>
                      </p>
                      <p>
                        <a href="#!" className="text-white">
                          Pro Football Focus?
                        </a>
                      </p>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-2 mx-auto mt-md-5 mb-md-0 mb-4">
                  <p>
                    <a href="https://apps.apple.com/app/betrics-betting-analytics/id1622382839" target="_blank">
                      <SuspenseImage src={AppStore} alt="AppStore" />
                    </a>
                  </p>
                  <p>
                    <a href="https://play.google.com/store/apps/details?id=com.betrics.io" target="_blank">
                      <SuspenseImage src={GooglePlay} alt="GooglePlay" />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="d-flex justify-content-center mb-2 socialIconsContainer">
            <a className="text-white m-1 socialIcons" href="https://pin.it/1RcFczC" role="button">
              <FaPinterestSquare size={24} />
            </a>
            <a className="text-white m-1 socialIcons" href="https://www.facebook.com/betricsio?mibextid=LQQJ4d" role="button">
              <FaFacebookSquare size={24} />
            </a>
            <a className="text-white m-1 socialIcons" href="https://instagram.com/betricsio?igshid=YmMyMTA2M2Y=" role="button">
              <FaInstagramSquare size={24} />
            </a>
            <a className="text-white m-1 socialIcons" href="https://www.linkedin.com/company/betrics/" role="button">
              <FaLinkedin size={24} />
            </a>
            <a className="text-white m-1 socialIcons" href="https://twitter.com/betricsio?s=21&t=9i9Ms9VFCadn_jo0W3t-2w" role="button">
              <FaTwitterSquare size={24} />
            </a>
            <a className="text-white m-1 socialIcons" href="https://youtube.com/@betrics8164" role="button">
              <FaYoutubeSquare size={24} />
            </a>
            <a className="text-white m-1 socialIcons" href="https://pin.it/1RcFczC" role="button">
              <FaRedditSquare size={24} />
            </a>
          </section>
          <div className="text-center termsCond">
            Terms and Conditions | Privacy Policy
          </div>
          <div className="text-center p-3 termsCond">
            <a className="text-white" href="#">
              © 2022 Betrics Corporation.
            </a>
          </div>
        </footer>
      </div>
    </>
  );
}
