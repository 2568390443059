import React, { useState } from 'react';
import Background from "./../../assets/images/home-bg.svg";
import IPhone1 from "./../../assets/images/iPhone_one.svg";
import IPhone2 from "./../../assets/images/iPhone_two.png";
import IPhone3 from "./../../assets/images/iPhone_three.png";
import Image1 from "./../../assets/images/image_1.svg";
import Image2 from "./../../assets/images/image_2.svg";
import Image3 from "./../../assets/images/advantages.svg";
import Banner from "./../../assets/images/banner-white.svg";
import HeroSection from "../../components/HeroSection";
import PowerImg from "./../../assets/images/power.jpg";
import SportsBetting from "./../../assets/images/SportsBetting.jpg";
import { MyTheme } from "../../theme";
import "./styles.css";
import Logos from "../../components/Logos";
import BulletListing from "../../components/ButtetListing";
import SuspenseImage from "../../components/SuspenseImage";
import AppStoreImages from "../../components/AppStore";
import Collapse from 'react-bootstrap/Collapse';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

export default function Home() {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <HeroSection image={Background} height={"90vh"} className={"heroSection"}>
        <div className="mask">
          <div className="d-flex h-100">
            <div className="text-white hero">
              <h2 className="mb-3">
                <span style={{ color: MyTheme.colors.secondaryText }}>
                  BETTER DECISIONS
                </span>{" "}
                FASTER
              </h2>
              <p className="p1">
                Discover the Bets You Need to Make and Start Winning
              </p>
              <p className="p2">
                Betrics mobile app helps sports bettors win more with a decision framework.
              </p>
              <Row className="imageText">
                <AppStoreImages />
              </Row>
              <p className="p2">
                Take your betting process to the next level. <br />
                Enter the Analytics Playground for sports fans with skin in the
                game.
              </p>
              <div className="subscribe-form">
                <input type="email" placeholder="Email id" />
                <button className="cust-btn">GET OUR APP</button>
              </div>
            </div>
          </div>
        </div>
      </HeroSection>
      <HeroSection image={Banner} height={"125vh"} className="gamblers-section">
        <div className="text-center gambler-container">
          <p className="gambler_heading">
            American gamblers lose $9 billion because nobody taught them a simple framework to make betting decisions.
          </p>
          <p className="gambler_text">
            <blockquote>
              <q>Life is too short to waste time feeling confused by manually analyzing wagers.</q>
            </blockquote>
            You need a decision framework.
            With a decision framework, you develop your range of beliefs by merging your intuition with the world’s finest data insights and analytical tools that do all of the heavy lifting for you.
            The result: you get real clear on the bets that have value, you execute them with bankroll discipline, and you smile while your stack grows 💰

          </p>
          <p className="gambler_text">
            If you do nothing…
            <a className='btn' onClick={() => setOpen(!open)} aria-controls="if-you-do-nothing" aria-expanded={open}>
              show more
            </a>
          </p>

          <Collapse in={open}>
            <div id="if-you-do-nothing" className="gambler_text">
              you will continue to get the results you are getting. For some of you, that might be ok.
              For others, let's face it - if you're not happy with your results, or how you're reaching your final betting decision, you have to consider making some changes.
              Change is scary, we get it. But how would you feel if you were reaching winning outcomes in less time with a higher perceived probability of achievement? Holy Grail of sports betting, right?
              Yeah, we agree, that's worth the effort to change some of your approach to how you organize your range of beliefs.
              We know you can do it with a decision framework.

            </div>
          </Collapse>
        </div>
        <div className="text-center">
          <div className="iPhones">
            <SuspenseImage src={IPhone2} alt="IPhone2" />
            <SuspenseImage src={IPhone1} alt="IPhone1" />
            <SuspenseImage src={IPhone3} alt="IPhone3" />
          </div>
          <h6 className="pt-4 gambler_heading">
            Someday soon we will all wager this way.
          </h6>
          <p className="gambler_text">
            Win more with a Betrics framework </p>
          <Row className="imageText">
            <AppStoreImages />
          </Row>
        </div>
      </HeroSection >

      {/* how we can become good bettors */}
      < div style={{ backgroundColor: "#F9F9F9" }
      }>
        <div className="text-center mt-4">
          <p className="pt-4 gambler_heading">
            Betrics is an intuitive mobile app made for sports bettors by sports bettors
          </p>
          <p className="gambler_text">
            Organize your betting intelligence into confidence with expert tools and resources.
            Seize the opportunity to join a community of sports
            bettors that drive a decision framework, gain more satisfaction from each win and
            become the envy of your friends.
          </p>
        </div>
        <div className="row justify-content-center drive-section">
          <div className="col-md-5 text-end drive-section_image order-md-2">
            <SuspenseImage src={Image1} alt="Image1" />
          </div>
          <div className="col-md-5 drive-section_text">
            <div className="drive-section_heading">
              <h2>Math PhD is NOT Required</h2>
              <p>
                The edge equation is simple - your confidence minus the odds. The math to get there is not. Ditch the calculator and let your Betrics Decision Engine do the work for you.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-2 mb-5">
          <div className="col-md-5 drive-section_image">
            <SuspenseImage src={Image2} alt="Image2" />
          </div>
          <div className="col-md-5 drive-section_text">
            <div className="drive-section_heading">
              <h2>Save Time Choosing Bets</h2>
              <p>
                As a cyborg you are probably busy cleaning up the mean streets of Detroit. Quickly identify betting opportunities systematically without wasting time poring over lines and data like a simple human.
              </p>
            </div>
          </div>
        </div>
        <h6 className="pt-4 gambler_heading">
          You’re Not Alone
        </h6>
        <p className="gambler_text">
          Make better decisions faster right now </p>
        <Row className="imageText text-center">
          <AppStoreImages />
        </Row>
      </div >

      {/* whats make betrics different */}
      < div className="text-center bulletin-section" >
        <h2 className="text-uppercase cust-header">
          What Makes Betrics.Io Different?
        </h2>
        <p>
          Betrics.io is the bridge between human insights & predictive
          analytics.
        </p>
        <div className="row justify-content-center">
          <div className="col-md-5 text-start">
            <p>As a Sports Bettors, you make sharp decisions faster...</p>
            <BulletListing
              title="Design your own decision workflow with access to BetricsTM from
                an unlimited pool of providers, integrated directly into their
                decision process"
            />
            <BulletListing title="Interpret the odds like the sharp money does" />
            <BulletListing title="ID betting opportunities programmatically" />
            <BulletListing title="Identify market trends and beat the closing line" />
            <BulletListing title="Manage risk, reduce losses, and analyze performance" />
            <BulletListing title="Gamify and celebrate success with friends" />
          </div>
        </div>

        <div>
          <h2 className="text-uppercase cust-header">
            BEATING OUT THE COMPETITION...
          </h2>
          <SuspenseImage src={Image3} alt="Image3" className="col-md-5 pros_cons" />
        </div>
      </div >

      {/* Guide */}
      < div style={{ backgroundColor: "#F9F9F9" }
      }>
        <div className="row justify-content-center drive-section">
          <div className="col-md-5 text-end drive-section_image order-md-2">
            <SuspenseImage src={SportsBetting} alt="Image1" />
          </div>
          <div className="col-md-5 drive-section_text">
            <div className="drive-section_heading">
              <h2>Savvy sports bettors are made, not born</h2>
              <p>
                We know who you are. You’re just like us. We care about helping you become the best version of
                you, the you the sports bettor you seek to be.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-2 mb-5">
          <div className="col-md-5 drive-section_image">
            <SuspenseImage src={PowerImg} alt="Image2" />
          </div>
          <div className="col-md-5 drive-section_text">
            <div className="drive-section_heading">
              <h2>You have the power already</h2>
              <p>
                You need to learn betting frameworks, but we’re gonna help you feel
                like you're remembering your greatest gift.
              </p>
            </div>
          </div>
        </div>
        <h6 className="pt-4 gambler_heading">
          We think you can do it and the Betrics mobile app is here to guide you
        </h6>
        <p className="gambler_text">
          Being intimidated by math is a '90s problem. Don't be held back </p>
        <Row className="imageText text-center">
          <AppStoreImages />
        </Row>
      </div>

      <div>
        <Container>
          <div className="justify-content-center drive-section">
            <Row className="text-center mt-4 mb-4">
              <Col>
                <p className="pt-4 gambler_heading">
                  Betrics app allows sports bettors to find winning bets and take action
                </p>
              </Col>
            </Row>
            <Row>
              <Col className='guide_section'>
                <b>
                  How it work
                </b>

              </Col>
              <Col sm="12">
                <ui className="guide_section">
                  <li> <b>App presents league matchups -</b>  shows team's and current market odds by sportsbook.
                  </li>
                  <li> <b>Connect to Insight Providers -</b> marketplace features professional projections, ratings, and rankings, referred to as Betrics™, that directly feed to a decision engine, aka model.
                  </li>
                  <li> <b>Discover the best bets fast -</b> bettors throttle the insights in their decision engine, run the engine to find bets that have value, and take action.
                  </li>
                </ui>
              </Col>
            </Row>
          </div>
          <p className="gambler_text">
            <b>
              🏆 Bettors win more with a Betrics decision framework from their mobile phone!
            </b>

          </p>
          <p className="gambler_heading">
            Win with Betrics and never look back </p>
          <Row className="imageText text-center">
            <AppStoreImages />
          </Row>
        </Container>

      </div>

      {/* Pricing */}
      <div style={{ backgroundColor: "#F9F9F9" }}>
        <Container>
          <h2 className="text-uppercase cust-header pt-4">
            Pricing
          </h2>

          <div >
            <Row >
              <Col sm="12">
                <p className="guide_section">
                  <b>Inflation Buster!</b>
                </p>
              </Col>
              <Col sm="12">
                <p className='guide_section'>
                  Get your edge right now at a reasonable cost.
                </p>
                <br />
                <p className='guide_section'>
                  FREE! That’s right, it’s free ninety-free right now while we’re in beta!
                </p>
                <p className="guide_section pt-4">
                  <b>
                    Get back more time at scale without a heavy sacrifice
                  </b>

                  <br />
                  The Betrics mobile app helps you install a decision framework so that you maintain your perceived probability of achievement without losing the time it takes to enjoy an effective win percentage.
                </p>
              </Col>
              <Col>
                <h6 className="pt-4 gambler_heading">
                  Get FREE! Beta Access for a Limited Time
                </h6>

                <Row className="imageText text-center">
                  <AppStoreImages />
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      {/* partners */}
      {/* <div className="text-uppercase partners_container">
        <h2 className="text-uppercase cust-header mb-5">Partners</h2>
        <Logos logo_container="home_logo_container" />
      </div> */}
    </div >
  );
}
