import React from "react";
import HeroSection from "../../components/HeroSection";
import AboutUsBg from "./../../assets/images/about_us_bg.svg";
import Image1 from "./../../assets/images/about-us_image1.svg";

import Image2 from "./../../assets/images/about-us_image2.png";
import Image3 from "./../../assets/images/about-us_image3.png";
import Image4 from "./../../assets/images/about-us_image4.png";
import Image5 from "./../../assets/images/about-us_image5.png";

import Image7 from "./../../assets/images/betrics_logo_bw.svg";
import Image8 from "./../../assets/images/pawan.png";
import "./styles.css";
import Logos from "../../components/Logos";
import SuspenseImage from "../../components/SuspenseImage";

export default function AboutUs() {
  return (
    <div>
      <HeroSection image={AboutUsBg} height={"40vh"}>
        <div className="headingr">
          <div className="d-flex h-100">
            <div className="text-white heading_container">
              <h2 className="mb-3">About Us</h2>
              <div className="heading_breadcrum">
                <a href="/">Home</a>
                <span>></span>
                <a href="/about-us">About</a>
              </div>
            </div>
          </div>
        </div>
      </HeroSection>

      <div className="row justify-content-center success-block">
        <div className="col-md-4 text-md-end success-block_image">
          <SuspenseImage src={Image1} alt="Image1" />
        </div>
        <div className="col-md-5">
          <div className="success-block_text-container">
            <h2>
              🚀 We are on a Mission…
            </h2>
            <h6>
              To help 5,000 sports bettors connect with the world's finest insight providers to make better decisions faster by February 2024 because everyone deserves to experience clarity.
            </h6>
            <p>Our mission is to provide a platform that empowers bettors with data-driven insights and cutting-edge technology to improve their chances of winning big. We believe that betting should be a strategic approach to sports events that involves research, analysis, and expert guidance.

              Our team of experts is dedicated to staying on top of the latest trends and developments in sports betting. We take responsible gambling seriously and provide resources and support to help our users gamble responsibly and stay in control of their betting activities.

              We want to create a community of bettors who are passionate about sports and committed to achieving success. Join us in our mission to transform the world of sports betting and make betting smarter, safer, and more fun than ever before.</p>
          </div>
        </div>
      </div>

      <h2 className="text-uppercase cust-header">OUR TEAM</h2>
      <div
        className="row justify-content-center imageText"
      >
        <div className="col-md-2 text-center">
          <SuspenseImage src={Image2} alt="Image2" className="phoneImage" />
        </div>
        <div className="col-md-4 team_info-text">
          <div className="team_info-block team_info-right_padding">
            <h2>Rob Wilder</h2>
            <h4>Action Rob</h4>
            <p>
              You may know him as Action Rob. He just gets sh** done. Sports
              betting expertise in market movement, fundamental analysis,
              behavioral discipline and psychology. He’s an on the field coach
              and human spark plug who brings passion and energy to every
              interaction. Never considers a path that results in anything less
              than a championship.
            </p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center imageText">
        <div className="col-md-2 text-center order-md-2">
          <SuspenseImage src={Image3} alt="Image3" className="phoneImage" />
        </div>
        <div className="col-md-4 team_info-text">
          <div className="team_info-block">
            <h2>Chris Velissaris</h2>
            <h4>Uncle Under</h4>
            <p>
              Nerdy enough to spend hours on end developing mathematical models
              and algorithms to bet on Korean Baseball, but just sporty enough
              to kick your ass for calling him nerdy. Rolls up his sleeves and
              parlays technical sports betting expertise to build predictive
              models, algorithms, & market opportunity tools the rest of us rely
              on. Every team needs a quant.
            </p>
          </div>
        </div>
      </div>
      <div
        className="row justify-content-center imageText"
      >
        <div className="col-md-2 text-center">
          <SuspenseImage src={Image4} alt="Image4" className="phoneImage" />
        </div>
        <div className="col-md-4 team_info-text">
          <div className="team_info-block team_info-right_padding">
            <h2>JT Whitman</h2>
            <h4>The Middler</h4>
            <p>
              Masters in Engineering and can outscore Ryan Fitzpatrick in the
              Wonderlic test. Expertise in sports simulation and sports betting
              portfolio risk optimization. He’s the Head of Product with a nose
              for the end zone.
            </p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center imageText">
        <div className="col-md-2 text-center order-md-2">
          <SuspenseImage src={Image5} alt="Image5" className="phoneImage" />
        </div>
        <div className="col-md-4 team_info-text">
          <div className="team_info-block">
            <h2>Jason Siegendorf</h2>
            <h4>The Statistician</h4>
            <p>
              The Statistician This stock market wizard devours data like John
              Daly at an all-you-can-eat buffet. He enjoys long walks on the
              beach, sarcasm, and statistics textbooks. He's the master of
              manipulating uncertainty into cash.
            </p>
          </div>
        </div>
      </div>
      {/* <div
        className="row justify-content-center imageText"
      >
        <div className="col-md-2 text-center">
          <SuspenseImage src={Image8} alt="Image4" className="phoneImage" />
        </div>
        <div className="col-md-4 team_info-text">
          <div className="team_info-block team_info-right_padding">
            <h2>Pawan Kushwah</h2>
            <h4>Software Engineer</h4>
            <p>
              I'm dedicated software engineer with a passion for designing,
              developing, and implementing software solutions.
              I have a strong background in computer science and programming languages and is
              committed to staying up-to-date with the latest technologies and trends in the industry.
              I have detail-oriented problem solver who enjoys working collaboratively with his team to
              achieve project goals.
            </p>
          </div>
        </div>
      </div> */}

      <div className="company">
        <h2 className="text-uppercase cust-header">Company Info</h2>
        <div className="row justify-content-center">
          <div className="col-md-3">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="company_info-block">
                <div className="pt-2 company_info-image">
                  <SuspenseImage src={Image7} alt="Image7" />
                </div>
                <h4 className="text-uppercase">Trademark</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center betric_pledge">
        <div className="col-md-2 text-start">
          <h2>
            BETRICS
            <sup>
              <small>TM</small>
            </sup>{" "}
            <br />
            PLEDGE
          </h2>
        </div>
        <div className="col-md-7">
          <div className="">
            <h3>
              Successful sports betting requires a plan better than Leeroy
              Jenkins.
            </h3>

            Gotta keep your head right and pledge responsible betting if you
            want to stay in the game. We care about you and it's way more fun
            when you're here keepin' it 100!
            <br />
            <br />
            Betrics Pledge: I will wager responsibly. I will not wager more than
            I can afford to lose. I will not wager impulsively. I will be
            informed and research wager positions intelligently. I will manage
            my bankroll with discipline. I will be intentional with wagering
            strategy. I will be accurate in my assessment of wagering risk. I
            will not chase losses. I will not wager under time pressure. I will
            take a break when needed. I will respect the value of every dollar.
            I will not be fooled by randomness. I will not blame others, the
            universe or forces that are not in my control. I will be aware of my
            intuition. I will be cognizant of my state of mind. I will identify
            psychological traps and learn how to avoid them. I understand that I
            must learn to lose so that I may learn to win. I will be accountable
            and seek help from a gambling addiction program if wagering
            negatively affects the quality of my life. I will be respectful of
            the process long-term and do not believe that wagering is the means
            to get rich quick. I will be curious about education and
            opportunities to increase my skill. I will be inclusive, contribute,
            and share my growth when possible. I will have fun.
          </div>
        </div>
      </div>

      <div className="text-uppercase partners_container">
        <h2 className="text-uppercase cust-header mb-5">Partners</h2>
        <Logos logo_container="about_logo_container" />
      </div>
    </div>
  );
}
