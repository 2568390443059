import React from "react";
import SuspenseImage from "../SuspenseImage";
import CheckIcon from "./../../assets/images/check-circle.svg";

export default function BulletListing(props) {
  return (
    <div style={{ display: "flex" }}>
      <SuspenseImage src={CheckIcon} alt="CheckIcon" className="listIcon" />
      <p className="bulletin-list_text">{props.title}</p>
    </div>
  );
}
