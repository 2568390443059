import React from "react";
import GLogo from "./../../assets/images/g_logo.svg";
import Rotowire from "./../../assets/images/rotowire.svg";
import Quarter4 from "./../../assets/images/quarter4.svg";
import Slogo from "./../../assets/images/s_logo.svg";
import Sportradar from "./../../assets/images/sportradar.svg";
import Customdfs from "./../../assets/images/customdfs.svg";
import "./styles.css";
import SuspenseImage from "../SuspenseImage";

export default function Logos(props) {
  return (
    <div className={`d-flex justify-content-around ${props.logo_container}`}>
      <div className={`text-center ${props.className}`}>
        <SuspenseImage src={GLogo} alt="GLogo" className="imageLogo" />
      </div>
      <div className={`text-center ${props.className}`}>
        <SuspenseImage src={Rotowire} alt="Rotowire" className="imageLogo" />
      </div>
      <div className={`text-center ${props.className}`}>
        <SuspenseImage src={Slogo} alt="Slogo" className="imageLogo" />
      </div>
      <div className={`text-center ${props.className}`}>
        <SuspenseImage src={Sportradar} alt="Sportradar" className="imageLogoBig" />
      </div>
      <div className={`text-center ${props.className}`}>
        <SuspenseImage src={Quarter4} alt="Quarter4" className="imageLogo" />
      </div>
      <div className={`text-center ${props.className}`}>
        <SuspenseImage src={Customdfs} alt="Customdfs" className="imageLogoBig" />
      </div>
    </div>
  );
}
