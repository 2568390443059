import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import TopNav from './components/TopNav';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Home from './pages/Home';
import OurApp from './pages/OurApp';

function App() {
  return (
    <>
      <Router>
          <TopNav />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/our-app" element={<OurApp />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/*" element={<div>
              Not Found
            </div>} />
          </Routes>
          <Footer />
      </Router>
    </>
  );
}

export default App;
