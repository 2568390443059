import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import HeroSection from '../../components/HeroSection';
import ContactUsBg from "./../../assets/images/contact_us_bg.svg";
import { GrLocation } from "react-icons/gr";
import { FiPhone } from "react-icons/fi";
import { RiBuildingLine } from "react-icons/ri";
import { FaSpinner, FaCheckCircle } from 'react-icons/fa';
import "./styles.css";
import Logos from '../../components/Logos';

export default function ContactUs() {
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    name: "",
    email: "",
    phone_number: "",
    message: ""
  })

  const contactApiCall = () => {
    if (success) return
    if (data?.name.trim() === "") return
    if (data?.email.trim() === "") return
    if (data?.phone_number.trim() === "") return
    if (data?.message.trim() === "") return
    setLoading(true)
    fetch('https://devapi.betrics.io/api/contact-us/', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        console.log("Success", response);
        // alert("Thank you for contacting us.")
        setData({
          name: "",
          email: "",
          phone_number: "",
          message: ""
        })
        setSuccess(true)
        setLoading(false)
      })
      .catch(error => {
        console.log("Error", error);
        setLoading(false)
      });
  }

  return (
    <div>
      <HeroSection image={ContactUsBg} height={"40vh"}>
        <div className="headingr">
          <div className="d-flex h-100">
            <div className="text-white heading_container">
              <h2 className="mb-3 text-uppercase">Contact Us</h2>
              <div className="heading_breadcrum">
                <a href="/">Home</a>
                <span>></span>
                <a href="/about-us">Contact</a>
              </div>
            </div>
          </div>
        </div>
      </HeroSection>
      <div className='row justify-content-center mt-4'>
        <div className="col-11 col-md-5">
          <form className="mb-5" method="post" id="contactForm" name="contactForm" noValidate="novalidate">
            <div className="row mt-2 contact-form">
              <h2 className="heading_text">Get In Touch</h2>
              <div className="col-md-12 form-group">
                <label htmlFor="name" className="col-form-label">Name <span className='text-danger'>*</span></label>
                <input type="text" className="form-control" name="name" id="name" value={data?.name} onChange={(e) => setData({ ...data, name: e.target.value })} />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12 form-group">
                <label htmlFor="email" className="col-form-label">Email <span className='text-danger'>*</span></label>
                <input type="email" className="form-control" name="email" id="email" value={data?.email} onChange={(e) => setData({ ...data, email: e.target.value })} />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12 form-group">
                <label htmlFor="phone_number" className="col-form-label">Phone No. <span className='text-danger'>*</span></label>
                <input type="number" className="form-control" name="phone_number" id="phone_number" value={data?.phone_number} onChange={(e) => setData({ ...data, phone_number: e.target.value })} />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12 form-group">
                <label htmlFor="message" className="col-form-label">Your Message <span className='text-danger'>*</span></label>
                <textarea className="form-control" name="message" id="message" cols={30} rows={7} style={{ resize: 'none' }} value={data?.message} onChange={(e) => setData({ ...data, message: e.target.value })} />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <button typRiBuildingLinee="button" style={{ width: '100%' }} disabled={loading} className={`btn btn-${success ? 'success' : 'primary'} rounded-0 py-2 px-4`}
                  onClick={() => contactApiCall()}
                >
                  {loading ?
                    <FaSpinner icon="spinner" className="spinner" size={20} />
                    : success ?
                      <FaCheckCircle size={20} />
                      : "Send Message"}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className='col-11 col-md-5 contact-details'>
          <div className='contact_items'>
            <div className='contact_icons'>
              <GrLocation size={24} />
            </div>
            <div className="contact_text">
              <h4>Our Address</h4>
              <p>Lake Forest, IL 60045, US</p>
            </div>
          </div>
          <div className='mt-4 contact_items'>
            <div className='contact_icons'>
              <FiPhone size={24} />
            </div>
            <div className="contact_text">
              <h4>Contact Info</h4>
              <p>Open a chat or give us call at</p>
              <a href="#asd">+1 8479513033</a>
            </div>
          </div>
          <div className='mt-4 contact_items'>
            <div className='contact_icons'>
              <RiBuildingLine size={24} />
            </div>
            <div className="contact_text">
              <h4>For Investor</h4>
              <p>Open a Link</p>
              <a href="https://www.fundable.com/betrics">https://www.fundable.com/betrics</a>
            </div>
          </div>
        </div>
      </div>
      <div className="text-uppercase partners_container">
        <h2 className="text-uppercase cust-header mb-5">Partners</h2>
        <Logos logo_container="contact_logo_container" />
      </div>
    </div>
  )
}
