
import GooglePlay from "../../assets/images/play_store.svg";
import AppStore from "../../assets/images/app_store.svg";
import SuspenseImage from "../SuspenseImage";
import Col from 'react-bootstrap/Col';


export default function AppStoreImages() {
    return (
        <>
            <Col>
                <a href="https://apps.apple.com/app/betrics-betting-analytics/id1622382839" target="_blank">
                    <SuspenseImage src={AppStore} alt="AppStore" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.betrics.io" target="_blank">
                    <SuspenseImage src={GooglePlay} alt="GooglePlay" />
                </a>
            </Col>
        </>
    )
}