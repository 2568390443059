import React from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import SuspenseImage from "../SuspenseImage";
import Logo from "./../../assets/images/betrics_logo.svg";
import "./styles.css";

export default function TopNav() {
  return (
    <div>
      <div
        className="text-center announcement">
        <p>
          Become a Part of Our Future
          <button className="announcement_btn cust-btn"> <a href="https://www.fundable.com/betrics" className="text-white text-decoration-none" target="_blank">
            Let's Go</a></button>
        </p>
      </div>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <SuspenseImage src={Logo} alt="Betrics" />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Nav.Link className={`tab_text ${window.location.pathname === '/' ? "active" : ""}`} href="/">
                HOME
              </Nav.Link>
              <Nav.Link className={`tab_text ${window.location.pathname === '/our-app' ? "active" : ""}`} href="/our-app">
                OUR APP
              </Nav.Link>
              <Nav.Link className={`tab_text ${window.location.pathname === '/about-us' ? "active" : ""}`} href="/about-us">
                ABOUT US
              </Nav.Link>
              <Nav.Link className={`tab_text ${window.location.pathname === '/contact-us' ? "active" : ""}`} href="/contact-us">
                CONTACT US
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
