import React from "react";
import HeroSection from "../../components/HeroSection";
import OurAppBg from "./../../assets/images/our_app_bg.svg";
import IPhone1 from "./../../assets/images/iPhone_one.svg";
import IPhone4 from "./../../assets/images/iPhone_four.svg";
import IPhone5 from "./../../assets/images/iPhone_five.svg";
import Group1 from "./../../assets/images/group-phone1.png";
import Group2 from "./../../assets/images/group-phone2.png";
import Group3 from "./../../assets/images/group-phone3.png";
import Group4 from "./../../assets/images/group-phone4.png";
import Group5 from "./../../assets/images/group-phone5.png";
import Group6 from "./../../assets/images/group-phone6.png";
import Group7 from "./../../assets/images/group-phone7.png";
import AppStoreImages from "../../components/AppStore";
import Row from 'react-bootstrap/Row';
import "./styles.css";
import Logos from "../../components/Logos";
import SuspenseImage from "../../components/SuspenseImage";

export default function OurApp() {
  return (
    <div>
      <HeroSection image={OurAppBg} height={"70vh"} className={"heroSection"}>
        <div className="maskOurPage text-center text-white">
          <h2 className="heroHeader">
            IT'S TIME TO TAKE YOUR <br />
            GAME TO THE NEXT LEVEL.
          </h2>
          <p>
            Betrics.io the intuitive, easy-to-use, software for made sports
            bettors by sports bettors.
          </p>
          <Row className="imageText text-center">
            <AppStoreImages />
          </Row>
        </div>
      </HeroSection>
      <div className="text-center iPhoneConatiner">
        <SuspenseImage src={IPhone4} alt="IPhone4" />
        <SuspenseImage src={IPhone1} alt="IPhone1" />
        <SuspenseImage src={IPhone5} alt="IPhone5" />
      </div>
      <div className="ourAppLogo">
        {/* <Logos
          logo_container="partners row justify-content-center"
          className="logoContainer col-6 col-lg-2"
        /> */}
      </div>

      <div className="image_text_container">
        <div className="row justify-content-center">
          <div className="col-md-3 text-center order-md-2">
            <SuspenseImage src={Group5} alt="Group1" className="phoneImage" />
          </div>
          <div className="col-md-3">
            <div className="verticallyCenterText margin_left_negative">
              <h2>MatchUp View</h2>
              <p>
                Matchup is a term used in sports betting to describe a competition between two teams or individuals.
              </p>
            </div>
          </div>
        </div>
        <div
          className="row justify-content-center  imageText_margin"
          style={{ backgroundColor: "#F9F9F9" }}
        >
          <div className="col-md-3 text-center">
            <SuspenseImage src={Group1} alt="Group2" className="phoneImage" />
          </div>
          <div className="col-md-3">
            <div className="verticallyCenterText">
              <h2>Engine</h2>
              <p>

                A decision engine in betting is a software system that uses algorithms to provide personalized betting recommendations based on data.

              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center imageText">
          <div className="col-md-3 text-center order-md-2">
            <SuspenseImage src={Group3} alt="Group3" className="phoneImage" />
          </div>
          <div className="col-md-3">
            <div className="verticallyCenterText margin_left_negative">
              <h2>War Room</h2>
              <p>
                In betting, a war room is a space where professional sports bettors gather to analyze data and identify profitable betting opportunities.

              </p>
            </div>
          </div>
        </div>
        <div
          className="row justify-content-center imageText"
          style={{ backgroundColor: "#F9F9F9" }}
        >
          <div className="col-md-3 text-center">
            <SuspenseImage src={Group4} alt="Group4" className="phoneImage" />
          </div>
          <div className="col-md-3">
            <div className="verticallyCenterText">
              <h2>Contest</h2>
              <p>

                A betting contest is a competition where participants compete against each other to make the most profitable wagers and win prizes.

              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center imageText">
          <div className="col-md-3 text-center order-md-2">
            <SuspenseImage src={Group2} alt="Group5" className="phoneImage" />
          </div>
          <div className="col-md-3">
            <div className="verticallyCenterText margin_left_negative">
              <h2>Bet tracking</h2>
              <p>
                Bet tracking is the process of keeping records of bets placed, their outcomes, and overall betting performance for analysis and improvement.

              </p>
            </div>
          </div>
        </div>
        <div
          className="row justify-content-center imageText"
          style={{ backgroundColor: "#F9F9F9" }}
        >
          <div className="col-md-3 text-center">
            <SuspenseImage src={Group6} alt="Group6" className="phoneImage" />
          </div>
          <div className="col-md-3">
            <div className="verticallyCenterText">
              <h2>book</h2>
              <p>

                A sportsbook is a platform where individuals can place bets on various sports events and outcomes, often operated by a bookmaker.

              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center imageText">
          <div className="col-md-3 text-center order-md-2">
            <SuspenseImage src={Group7} alt="Group7" className="phoneImage" />
          </div>
          <div className="col-md-3">
            <div className="verticallyCenterText margin_left_negative">
              <h2>marketplace</h2>
              <p>
                A betting marketplace is an online platform where individuals can buy, sell, or exchange bets with each other, often at better odds.

              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
